import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import RightIcon from '../../images/right.svg'
import authAxios from '../../helpers/axios'
import { toast } from 'react-toastify'
import { InfoToast } from '../../helpers/toasters'
import SearchInput from '../../components/SearchInput'
import { BOOKING_BOOKED, BOOKING_FINISHED, BOOKING_ONGOING, LIMIT } from '../../helpers/constants'
import { useParams, useSearchParams } from 'react-router-dom'
import { getDateFormat, getDateTimeFormat } from '../../helpers/utils'
import Loader from '../../components/Loader'
import Popup from '../../components/Popup'
import Input from '../../components/Input'
import ImageUploader from '../../components/ImageUploader'
import SingleImageHolder from '../../components/SingleImageHolder'
import Select from '../../components/Select'
import DatePicker from 'react-datepicker'
import AddRideDue from './components/AddRideDue'

export default function RideInfo() {

    const {id} = useParams()
    const [rideInfo,setRideInfo] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})
    const [showStart,setShowStart] = useState(false)
    const [showCancel,setShowCancel] = useState(false)
    const [showEnd,setShowEnd] = useState(false)
    const [loading,setLoading] = useState(true)
    const [showAddDue,setShowAddDue] = useState(false)
    const [submitting,setSubmitting] = useState(false)


    async function getRideInfo(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/booking/${id}?populate=true`)
            console.log('data',res.data)
            if(res.data) setRideInfo(res.data)
            setLoading(false)
        } catch (error) {
          setLoading(false)
          InfoToast(error.response.data.error.message)
        }
    }
    useEffect(()=>
    {
        getRideInfo();
    },[])
   
    async function onStart(e,startKms,pickupTime,startFuel,startImage){

        try 
        {
            e.preventDefault();
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/booking/start-ride/${id}`,{startKms,pickupTime,startFuel,startImage})
            console.log('data',res.data)
            if(res.data) await getRideInfo()
            setShowStart(false)
            setLoading(false)
        } catch (error) {
          setLoading(false)
          console.log(error.response.data.error.message)
          InfoToast(error.response.data.error.message)
        }
    }

    async function onEnd(e,endKms,dropTime,endFuel,endImage,manualRefund,manualRefundAmount){

        try 
        {
            e.preventDefault();
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/booking/end-ride/${id}`,{endKms,dropTime,endFuel,endImage,manualRefund,manualRefundAmount})
            console.log('data',res.data)
            if(res.data) await getRideInfo()
            setShowEnd(false)
            setLoading(false)
        } catch (error) {
          setLoading(false)
          console.log(error.response.data.error.message)
          InfoToast(error.response.data.error.message)
        }
    }
   
    async function onCancel(e,startKms,pickupTime){

        try 
        {
            e.preventDefault();
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/booking/cancel-ride/${id}`,{startKms,pickupTime})
            console.log('data',res.data)
            if(res.data) await getRideInfo()
            setShowStart(false)
            setLoading(false)
        } catch (error) {
          setLoading(false)
          console.log(error.response.data.error.message)
          InfoToast(error.response.data.error.message)
        }
    }

    const onDueSubmit = async(e,data)=>
      {
          try 
          {
              e.preventDefault()
              let res;
              setSubmitting(true)
                  res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/due`,{bookingId:rideInfo.id,userId:rideInfo.userId,...data})
                  InfoToast('Due Created')
                  setSubmitting(false)
                  setShowAddDue(false)
          } catch (error) {
              setSubmitting(false)
              // console.log(error.response.data.error[0])
              InfoToast(error.response.data.error[Object.keys(error.response.data.error)[0]])
          }
      }




  return (
    !loading ? <div className='max-w-7xl mx-auto px-6'>

        <div className='bg-white rounded-md shadow-sm px-6 py-4 mt-4'>
            <div className='pb-3 border-b border-b-slate-200 flex w-full justify-between'>
            <p className='text-sm font-medium text-[#959595] '>Ride Information</p>
            <div className='flex'>
            {rideInfo.status === BOOKING_BOOKED ? <button className='mr-2 btn-xs border border-red-500 text-red-700 hover:bg-red-200 hover:text-red-700' onClick={()=>setShowCancel(true)}>Cancel Ride</button> : null}
            {rideInfo.status === BOOKING_FINISHED ? <button className='btn-xs' onClick={()=>setShowAddDue(true)}>Add Due</button> : null}
            {rideInfo.status === BOOKING_BOOKED ? <button className='btn-xs' onClick={()=>setShowStart(true)}>Start Ride</button> : null}
            {rideInfo.status === BOOKING_ONGOING ? <button className='btn-xs' onClick={()=>setShowEnd(true)}>End Ride</button> : null}
            </div>
            </div>
            <div className='rounded-xl w-full flex py-0 px-0 overflow-hidden z-10'>
            <div className='h-full flex flex-col w-full'>
              <div className='w-full md:flex justify-between items-center px-6 mt-10'>
                <div>
                  <div className='flex items-center'>

                   <div className={`w-[80px] h-[80px] rounded-lg overflow-hidden relative mr-4`}>
                      <img className='h-full w-full' src={rideInfo.vehicle.images[0].url}/>
                    </div>
                  <div>
                  <p className='text-base font-semibold text-black'>{`${rideInfo.vehicle.brand.name} ${rideInfo.vehicle.vehicleName}`}</p>
                  <p className='text-xs text-[#959595] capitalize md:mt-1'>{rideInfo.vehicle.vehicleFuelType} &middot; {rideInfo.vehicle.vehicleSeats} Seater &middot; {rideInfo.vehicle.vehicleYear}</p>
                  <p className='text-xs text-[#959595] md:mt-1 uppercase'>#{rideInfo.bookingId}</p>
                  <div className='block md:hidden'>
                  <p className='text-[10px] inline-block font-medium rounded-full text-black uppercase status-text bg-[#6ef7ae2e] px-2 py-1'>{`${rideInfo.status}`}</p>
                </div>
                  </div>
                  </div>
                </div>
                <div className='hidden md:block px-4'>
                  <p className='text-xs inline-block font-medium text-black uppercase status-text bg-[#1d6f4348] rounded-sm px-3 py-2'>{`${rideInfo.status}`}</p>
                </div>
              </div>

              <div className='mt-2 py-6 md:py-6 md:px-6'>
                  <p className='text-xs uppercase font-semibold text-[#959595]'>Ride Information</p>
            <div className='grid md:grid-cols-4 grid-cols-2 gap-y-6 justify-between mt-2 md:mt-4'>
            <div className='pr-4'>
              <div className='flex mt-2'>
                {/* <RideDot/> */}
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>Selected Pickup Time</p>
                  <p className='ml-2 text-sm text-[#151515]'>{getDateTimeFormat(rideInfo.startTime)}</p>

                </div>
                </div>
            </div>
            <div className='pr-4'>
              <div className='flex mt-2'>
                {/* <RideDot type='end'/> */}
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>Selected Drop Time</p>
                  <p className='ml-2 text-sm text-[#151515]'>{getDateTimeFormat(rideInfo.endTime)}</p>

                </div>
                </div>
            </div>
              <div className='flex items-center'>
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>Total Amount</p>
                  <p className='ml-2 text-sm text-[#151515]'>Rs.{`${rideInfo.totalAmount}`}</p>
                </div>
                </div>
              {rideInfo.depositAmount > 0 ? <div className='flex items-center'>
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>Refundable Deposit</p>
                  <p className='ml-2 text-sm text-[#151515]'>Rs. {rideInfo.depositAmount}</p>
                </div>
                </div> : null}
              </div>
              </div>

              {<div className='mb-4 md:px-6'>
                  {/* <p className='ml-2 text-xs uppercase font-semibold text-[#959595]'>Ride Information</p> */}
            <div className='grid md:grid-cols-4 grid-cols-2 gap-y-6 justify-between mt-2 md:mt-4'>
              
              <div className='flex'>
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>Actual Pickup Time</p>
                  <p className='ml-2 text-sm text-[#151515]'>{rideInfo.pickupTime ? getDateTimeFormat(rideInfo.pickupTime) : '-'}</p>
                </div>
                </div>
              <div className='flex'>
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>Actual Drop Time</p>
                  <p className='ml-2 text-sm text-[#151515]'>{rideInfo.dropTime ? getDateTimeFormat(rideInfo.dropTime) : '-'}</p>
                </div>
                </div>
            <div>
              <div className='flex'>
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>Pickup Kms / Fuel</p>
                  <p className='ml-2 text-sm text-[#151515]'>{rideInfo.startKms ? `${rideInfo.startKms} Kms ${rideInfo.startFuel ? `(${rideInfo.startFuel}%)` : ''}` : '-'}</p>
                </div>
                </div>
            </div>
            <div>
              <div className='flex'>
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>Drop Kms / Fuel</p>
                  <p className='ml-2 text-sm text-[#151515]'>{rideInfo.endKms ? `${rideInfo.endKms} Kms ${rideInfo.endFuel ? `(${rideInfo.endFuel}%)` : ''}` : '-'}</p>

                </div>
                </div>
            </div>
            <div>
              <div className='flex'>
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>Kms Alloted</p>
                  <p className='ml-2 text-sm text-[#151515]'>{rideInfo.kmAlloted ? `${rideInfo.kmAlloted} Kms` : 'Not Available'}</p>
                </div>
                </div>
            </div>
            <div>
              <div className='flex'>
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>Pickup/Delivery Type</p>
                  <p className='ml-2 text-sm text-[#151515] capitalize'>{rideInfo.deliveryType ? rideInfo.deliveryType : 'Not Available'}</p>
                </div>
                </div>
            </div>
            <div>
              <div className='flex'>
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>Delivery Fee</p>
                  <p className='ml-2 text-sm text-[#151515] capitalize'>{rideInfo.deliveryFee ? `Rs.${rideInfo.deliveryFee}` : 'Not Available'}</p>
                </div>
                </div>
            </div>
            <div>
              <div className='flex'>
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>Kms Alloted</p>
                  <p className='ml-2 text-sm text-[#151515]'>{rideInfo.kmAlloted ? `${rideInfo.kmAlloted} Kms` : 'Not Available'}</p>
                </div>
                </div>
            </div>
            <div>
              <div className='flex'>
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>Picup/Drop Location</p>
                  {rideInfo.lat ? <a href={`https://www.google.com/maps?q=${rideInfo.lat},${rideInfo.lng}`} className='underline ml-2 text-sm text-[#151515]'>Open Link</a> : 'Not Available'}
                </div>
                </div>
            </div>
            <div>
              <div className='flex'>
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>Address</p>
                  <p className='ml-2 text-sm text-[#151515]'>{rideInfo.address ? rideInfo.address : 'Not Available'}</p>
                </div>
                </div>
            </div>
            <div>
              <div className='flex'>
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>Start Ride Image</p>
                  <div className='w-[160px] h-[90px] relative bg-[#e3e3e3] rounded-sm overflow-hidden'>
                    {rideInfo.startImage ? <img src={rideInfo.startImage} className='w-full h-full'/> : null}
                    </div>

                </div>
                </div>
            </div>
            <div>
              <div className='flex'>
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>End Ride Image</p>
                  <div className='w-[160px] h-[90px] relative bg-[#e3e3e3] rounded-sm overflow-hidden'>
                    {rideInfo.endImage ? <img src={rideInfo.endImage} className='w-full h-full'/> : null}
                    </div>

                </div>
                </div>
            </div>
              </div>
              </div> }

              <div className='mt-2 py-6 md:py-6 md:px-6 border-t border-t-[#e3e3e3]'>
                  <p className='text-xs uppercase font-semibold text-[#959595]'>Review Ride</p>
            <div className='flex md:mt-4'>
              <div className='flex mt-2'>
                { rideInfo.status === BOOKING_FINISHED ? <div className="review">
                  {[...Array(5)].map((_, index) => (
                    <span
                      key={index}
                      className={"color-[#000]"} // Conditionally apply "filled" class based on rating
                      // onMouseEnter={() => handleMouseEnter(index)}
                      // onMouseLeave={handleMouseLeave}
                      // onClick={() => handleClick(index)}
                    >★</span>
                  ))}
                </div>
                : <div className='text-center'>
                </div>}
                </div>

              </div>
              </div>
              
              
              <div className='mt-2 py-6 md:py-6 md:px-6 border-t border-t-[#e3e3e3]'>
                  <p className='ml-2 text-xs uppercase font-semibold text-[#959595]'>Payment Information</p>
            <div className='flex mt-2 md:mt-4'>
            <div className='pr-4 w-full'>
              <div className='grid md:grid-cols-4 grid-cols-2 gap-y-4 md:gap-0 mt-2 justify-between'>
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>Status</p>
                  <p className='ml-2 text-sm text-[#000] capitalize'>{rideInfo.transaction ? rideInfo.transaction.paymentStatus : 'Not Available'}</p>
                </div>
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>Payment Method</p>
                  <p className='ml-2 text-sm text-[#000] uppercase'>{rideInfo.transaction && rideInfo.transaction.paymentMethod ? rideInfo.transaction.paymentMethod : 'Not available'}</p>
                </div>
                <div>
                  <p className='ml-2 text-xs text-[#959595]'>Amount</p>
                  <p className='ml-2 text-sm text-[#000]'>{rideInfo.transaction ? rideInfo.transaction.amount/100 : 'Not Available'}</p>
                </div>
                </div>
            </div>
              </div>
              </div>
              {rideInfo.bookingType === 'online' && rideInfo.isRefunded ? <RefundInformation rideInfo={rideInfo}/> : null}
              </div>
        </div>
        </div>
        {  showStart? <StartPopup onClose={()=>setShowStart(false)} onStart={onStart}/> : null}
        {  showCancel? <CancelPopup onClose={()=>setShowCancel(false)} onStart={onCancel}/> : null}
        {  showEnd ? <EndPopup onClose={()=>setShowEnd(false)} onEnd={onEnd}/> : null}
        {  showAddDue ? <AddRideDue submitting={submitting} onClose={()=>setShowAddDue(false)} onSubmit={onDueSubmit}/> : null}
    </div> : <Loader/>
  )
}




const RefundInformation = ({rideInfo})=>
{
    return               <div className='mt-2 py-6 md:py-6 md:px-6 border-t border-t-[#353535]'>
    <p className='ml-2 text-xs uppercase font-semibold text-[#959595]'>Refund Information</p>
    <div className='flex mt-2 md:mt-4'>
    <div className='pr-4 w-full'>
    <div className='grid md:grid-cols-4 grid-cols-2 gap-y-4 md:gap-0 mt-2 justify-between'>
      <div>
        <p className='ml-2 text-xs text-[#959595]'>Payment Id</p>
        <p className='ml-2 text-sm text-[#000] capitalize'>{rideInfo.refunds.paymentId}</p>
      </div>
      <div>
        <p className='ml-2 text-xs text-[#959595]'>Refund Method</p>
        <p className='ml-2 text-sm text-[#000] uppercase'>{rideInfo.transaction.paymentMethod ? rideInfo.transaction.paymentMethod : 'Not available'}</p>
      </div>
      <div>
        <p className='ml-2 text-xs text-[#959595]'>Status</p>
        <p className='ml-2 text-sm text-[#000] capitalize'>{rideInfo.refunds.status}</p>
      </div>
      <div>
        <p className='ml-2 text-xs text-[#959595]'>Amount</p>
        <p className='ml-2 text-sm text-[#000]'>Rs.{rideInfo.refunds.amount}</p>
      </div>
      </div>
    </div>
    </div>
    </div>
}

const StartPopup = ({onClose,onStart})=>
{
    const [startData,setStartData] = useState({startTime:'',startKms:'',startFuel:'',startImage:''})
    return <Popup title={'Start Ride'} onClose={onClose} formName={'startRide'} submitTitle={'Start Ride'} onSubmittingTitle='Starting Ride...'>
        <form name='startRide' id="startRide" onSubmit={(e)=>onStart(e,startData.startKms,startData.startTime,startData.startFuel,startData.startImage)}>
        <div className="grid grid-cols-2 gap-4">
        <div className='col-span-2'>
            <div>
              <SingleImageHolder name={'startImage'} label='Select Start Image' image={startData.startImage} setImage={(data)=>setStartData(prev=>({...prev,startImage:data}))}/>
            </div>
        </div>
            <div className='relative'>
                <label>Start Time</label>
                <DatePicker required={true} monthClassName='bg-white' wrapperClassName='react-individual-datepicker-wrapper bg-white text-xs w-full block' calendarClassName='bg-white' className=' border py-2 px-1.5 rounded-sm w-full z-10' popperClassName='react-individual-datepicker w-full' showTimeSelect={true} timeInputLabel='Time' timeIntervals={5} selected={startData.startTime} onChange={(date) => setStartData(prev=>({...prev,startTime:date}))} dateFormat="dd-MM-yyyy hh:mm aa"/>
            </div>
            <div>
                <label>Start Kms</label>
                <Input type='text' placeholder={'Enter Starting Kms'} value={startData.startKms} required={true} setValue={(value)=>setStartData(data=>({...data,startKms:value}))}/>
            </div>
            <div>
                <label>Start Fuel (In %)</label>
                <Input type='number' placeholder={'Enter Starting Fuel'} value={parseInt(startData.startFuel)} required={true} setValue={(value)=>setStartData(data=>({...data,startFuel:parseInt(value)}))}/>
            </div>
        </div>
        </form>
    </Popup>
}

const EndPopup = ({onClose,onEnd})=>
{
    const [endData,setEndData] = useState({endTime:'',endKms:'',endFuel:'',endImage:'',manualRefund:false,manualRefundAmount:0})
    return <Popup title={'End Ride'} onClose={onClose} formName={'endRide'} submitTitle={'End Ride'} onSubmittingTitle='Ending Ride...'>
    <form name='endRide' id="endRide" onSubmit={(e)=>onEnd(e,endData.endKms,endData.endTime,endData.endFuel,endData.endImage,endData.manualRefund,endData.manualRefundAmount)}>
        <div className="grid grid-cols-2 gap-4">
        <div className='col-span-2'>
            <div>
              <SingleImageHolder name={'endImage'} label='Select End Image' image={endData.endImage} setImage={(data)=>setEndData(prev=>({...prev,endImage:data}))}/>
            </div>
        </div>
            <div>
                <label>End Time</label>
                <DatePicker required={true} monthClassName='bg-white' wrapperClassName='react-individual-datepicker-wrapper bg-white text-xs w-full block' calendarClassName='bg-white' className=' border py-2 px-1.5 rounded-sm w-full z-10' popperClassName='react-individual-datepicker w-full' showTimeSelect={true} timeInputLabel='Time' timeIntervals={5} selected={endData.endTime} onChange={(date) => setEndData(prev=>({...prev,endTime:date}))} dateFormat="dd-MM-yyyy hh:mm aa"/>
            </div>
            <div>
                <label>End Kms</label>
                <Input type='text' placeholder={'Enter Ending Kms'} value={endData.endKms} required={true} setValue={(value)=>setEndData(data=>({...data,endKms:value}))}/>
            </div>
            <div>
                <label>End Fuel</label>
                <Input type='number' placeholder={'Enter Ending Fuel'} value={endData.endFuel} required={true} setValue={(value)=>setEndData(data=>({...data,endFuel:value}))}/>
            </div>
            <div></div>
            <div>
                <label>Refund Type</label>
                <Select options={[{name:'Auto Refund',value:false},{name:'Manual Refund',value:true}]} placeholder={'Select Refund Type'} value={endData.manualRefund} required={true} setValue={(value)=>setEndData(data=>({...data,manualRefund:value}))}/>
            </div>
            { endData.manualRefund === true || endData.manualRefund === 'true' ? <div>
                <label>Refund Amount (In Rs.)</label>
                <Input type='number' placeholder={'Enter Refund Amount'} value={endData.manualRefundAmount} required={true} setValue={(value)=>setEndData(data=>({...data,manualRefundAmount:value}))}/>
            </div> : null}
        </div>
        </form>
    </Popup>
}

const CancelPopup = ({onClose,onCancel})=>
{
    const [startData,setStartData] = useState({startTime:'',startKms:''})
    return <Popup title={'Cancel Ride'} onClose={onClose} formName={'cancelRide'} submitTitle={'Cancel Ride'} onSubmittingTitle='Cancelling Ride...'>
        <form name='cancelRide' id="cancelRide" onSubmit={(e)=>onCancel(e,startData.startKms,startData.startTime)}>
        <div className="grid grid-cols-2 gap-4">
            <div>
                <label>Start Time</label>
                <Input type='datetime-local' placeholder={'Enter Starting Time'} value={startData.startTime} required={true} setValue={(value)=>setStartData(data=>({...data,startTime:value}))}/>
            </div>
            <div>
                <label>Start Kms</label>
                <Input type='text' placeholder={'Enter Starting Kms'} value={startData.startKms} required={true} setValue={(value)=>setStartData(data=>({...data,startKms:value}))}/>
            </div>
        </div>
        </form>
    </Popup>
}
