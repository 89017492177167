import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import authAxios from "../../../helpers/axios"
import AsyncSelect from 'react-select/async';

export default function ManageDue({onClose,onSubmit,updateData,edit,submitting})
{
    const [manageData,setManageData] = useState({userId:'',reason:'',amount:'',bookingId:''})
    const [bookings,setBookings] = useState([])
    const [users,setUsers] = useState([])
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editAdmin';
    const [image,setImage] = useState(null)
    const [croppedImage,setCroppedImage] = useState()

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setShowCrop(true)
        setImage(URL.createObjectURL(event.target.files[0]));
        }
        }

        async function loadUsers(searchText){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/user?search=${searchText}`)
            setUsers(res.data.data)
            return res.data.data
        }

    useEffect(()=>
    {
        async function getBookings(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/booking/user/${manageData.userId.id}`)
            setBookings(res.data)
        }
        if(manageData.userId) getBookings()
    },[manageData.userId])



    return <><Popup submitting={submitting} onSubmittingTitle={'Creating Due'} title={edit ? 'Update Due' : 'Create Due'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData,userId:manageData.userId.id})} id={formName} className='grid grid-cols-2 gap-x-3 gap-y-0 w-full'>
            <div>
                <label>User*</label>
                <AsyncSelect onChange={(item)=>setManageData(prev=>({...prev,userId:item}))} loadOptions={loadUsers} getOptionLabel={item=><div><p className="text-xs font-medium">{`${item.name}`}</p><p className="text-xs">{item.contactNumber}</p></div>} getOptionValue={item=>item.id} />
            </div>
            <div>
                <label>Amount</label>
                <Input type='number' placeholder={'Enter Amount'} value={manageData.amount} required={true} setValue={(value)=>setManageData(data=>({...data,amount:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Reason</label>
                <textarea className="resize-none text-input" placeholder={'Enter Due Reason'} value={manageData.reason} required={true} onChange={(e)=>setManageData(data=>({...data,reason:e.target.value}))}/>
            </div>
            <div>
                <label>Booking</label>
                <Select placeholder={'Select Booking'} required={false} options={bookings} customLabel={'bookingId'} customValue={'id'}  value={manageData.bookingId} setValue={(value)=>setManageData(data=>({...data,bookingId:value}))}/>
            </div>
        </form>
    </Popup>
    </>
}